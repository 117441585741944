<template>
  <div ref="body">
    <custom-header active="global" :subActive="current"></custom-header>

    <div class="content-container">
      
      <!-- <div class="title text-bold">{{name}} 精彩回顾</div> -->
      <div class="title text-bold">{{name}}</div>
      
      <h1>精彩视频</h1>
      <div class="video-box">
        <van-swipe ref="exh" class="scroll-contianer" :loop="true"  @change="exhChange" :show-indicators="false">
          <van-swipe-item v-for="(video, idx) in videoList" :key="idx" class="swipe-item">
            <div class="item" @click="toPlay(video.url)">
              <img :src="video.cover" alt="" class="exh-img">
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" size="36" class="btn left" @click="turnLeft('exh')" />
              <van-icon name="down" size="36" class="btn right" @click="turnRight('exh')" />
            </div>
          </template>
        </van-swipe>
        <van-swipe class="scroll-contianer1" :loop="true" :show-indicators="false">
          <van-swipe-item v-for="(video, idx) in videoList" :key="idx" class="swipe-item">
            <div class="item" @click="toPlay(video.url)">
              <img :src="video.cover" alt="" class="exh-img">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      
      <h1>精彩瞬间</h1>
      <div class="video-box">
        <!-- <van-swipe ref="img" class="scroll-contianer" :loop="true" :autoplay="60000" @change="imgChange" :show-indicators="false">
          <van-swipe-item v-for="(imgs, index) in imgList" :key="index">
            <div class="item1 flex flex-wrap">
              <template v-for="(item, idx) in imgs">
                <el-image :src="item" :key="idx" class="img-item" :preview-src-list="imgList1" lazy />
              </template>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" size="36" class="btn left" @click="turnLeft('img')" />
              <van-icon name="down" size="36" class="btn right" @click="turnRight('img')" />
            </div>
          </template>
        </van-swipe> -->
        
        <div class="item1 flex flex-wrap">
          <template v-for="(item, idx) in imgList1">
            <el-image :src="item" :key="idx" class="img-item" :preview-src-list="imgList1" lazy />
          </template>
        </div>
      </div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <van-overlay z-index="20" :show="show">
      <div class="wrapper" @click.stop>
        <div class="video-container relative">
          <video id="my-video" src="" type="video/mp4" controls class="fill"></video>
          <div class="close-icon absolute" @click="hideVideo">
            <van-icon name="clear" class="" />
          </div>
          <div class="close-btn absolute" @click="hideVideo">关闭</div>
        </div> 
      </div>
    </van-overlay>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon, Overlay } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getSessionDetailList } from '@/api/index.js';

export default {
  // name: '精彩资源',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      sessionId: '',
      name: '',
      current: 'library',
      imgList: [],
      imgList1: [],
      show: false,
      videoList: [],
      video: null,
    }
  },
  created() {
    let {id, name} = this.$route.query;
    if (name) {
      this.name = decodeURIComponent(name);
    }
    this.sessionId = id;
    this.getSessionDetails(1); // 1-图片
    this.getSessionDetails(2); // 2-视频
  },
  destroyed () {
    this.cancelVideo();
  },
  methods: {
    getSessionDetails(type) {
      let params = {
        type, // 1图片2视频
        pageNo: 1,
        pageSize: 40,
        sessionId: this.sessionId
      }
      getSessionDetailList(params).then(res => {
        let list = res.resultData.records || [];
        if (type == 1) {
          let imgList = list.map(e => e.img);
          // let arr = [];
          // if (imgList.length > 0) {
          //   for (let i = 0; i < imgList.length; i++) {
          //     arr.push(imgList.slice(i, i += 4))
          //   }
          // }
          // this.imgList = arr;
          this.imgList1 = imgList;
        } else {
          let videoList = list.map(e => ({url: e.video, cover: e.img}));
          // let arr = [];
          // if (videoList.length > 0) {
          //   for (let i = 0; i < videoList.length; i++) {
          //     arr.push(videoList.slice(i, i += 3))
          //   }
          // }
          // this.videoList = arr;
          this.videoList = videoList;
        }
      })
    },
    turnLeft(key) {
      this.$refs[key].prev();
    },
    turnRight(key) {
      this.$refs[key].next();
    },
    exhChange(e) {
      if (e == 8) {
        // this.swipeToFirst('exh');
      }
    },
    imgChange(e) {
      if (e == 8) {
        // this.swipeToFirst('exh');
      }
    },
    toPlay(url) {
      this.video = document.querySelector("#my-video");
      if (this.video) {
        this.video.src = url;
        this.video.load();
        this.show = true;
      }
    },
    hideVideo() {
      this.show = false;
      this.cancelVideo();
    },
    cancelVideo() {
      let video = this.video;
      if (video) {
        // 销毁 VIDEO 元素
        video.pause(); // 停止播放
        // video.removeAttribute('src'); // 清空srcs属性来停止加载
        video.src = ''; // 清空srcs属性来停止加载
        video.load(); // 调用 load() 方法，以确保所有相关数据都被卸载。
        // 将 VIDEO 元素的事件监听器设为 null，以避免内存泄漏。
        video.oncanplaythrough = null;
        video.onerror = null;
        // video.remove();
        video = null; // 最后将 VIDEO 元素赋值为 null，以释放其占用的内存。
      }
    }

  },
}
</script>

<style scoped lang="less">

// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  
  .content-container {
    width: 350px;
    margin: 10px auto 20px;
    
    .title {
      text-align: center;
      font-size: 16px;
      margin: 10px auto 20px;
    }
    
    h1 {
      margin-bottom: 8px;
      font-size: 14px;
    }
    
    .video-box {
      margin-bottom: 30px;
    }
    
    .exh-img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 4px;
    }
      
    .item1 {
      position: relative;
      
      .img-item {
        width: 110px;
        height: auto;
        margin: 0 10px 10px 0;
        border-radius: 4px;
        
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
    
    .scroll-contianer {
      display: none;
    }
    
    .scroll-contianer1 {
      width: 100%;
    }
    
  }
  
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .video-container {
    width: 355px;
    height: 200px;
    border-radius: 4px;
    
    .close-icon {
      font-size: 36px;
      top: -54px;
      right: 0;
      z-index: 10;
      color: #fff;
      cursor: pointer;
    }
    
    .close-btn {
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      font-size: 14px;
      padding: 5px 20px;
      border-radius: 4px;
    }
  }
  
}

// PC端
@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
    
  .content-container {
    width: 280px;
    margin: 10px auto 20px;
    
    .title {
      text-align: center;
      font-size: 5px;
      margin: 10px auto 20px;
    }
    
    h1 {
      margin-bottom: 4px;
      font-size: 4px;
    }
    
    .video-box {
      margin-bottom: 18px;
    }
    
    .swipe-item {
      width: 90px!important;
    }
    .item,
    .item1 {
      display: inline-block;
      width: 100%;
      padding: 0 2px;
      box-sizing: border-box;
      
      .exh-img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 1px;
      }
      
    }
    
    .item1 {
      position: relative;
      padding: 0;
      
      .img-item {
        width: 66px;
        height: auto;
        margin: 0 4px 4px 0;
        border-radius: 1px;
        
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
    
    .scroll-contianer1 {
      display: none;
    }
    
    .scroll-contianer {
      width: 100%;
      
      .turn-btn-box {
        display: none;
        right: 0;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        width: 100%;
        box-sizing: border-box;
        padding: 0 4px 0 6px;
        
        .btn {
          margin: 0 1px;
          padding: 1px;
          background-color: rgba(0, 0, 0, .6);
          color: #fff;
          border-radius: 10px;
          
          &.left {
            transform: rotate(90deg);
            float: left;
          }
          &.right {
            transform: rotate(-90deg);
            float: right;
          }
        }
        
      }
      
      &:hover {
        .turn-btn-box {
          display: block;
        }
      }
    }
    
  }
  
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .video-container {
    width: 200px;
    height: 112px;
    border-radius: 1px;
    
    .close-icon {
      font-size: 10px;
      top: -18px;
      right: 0;
      z-index: 10;
      color: #fff;
      cursor: pointer;
    }
    
    .close-btn {
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      font-size: 3.6px;
      padding: 2px 6px;
      border-radius: 1px;
      cursor: pointer;
    }
  }
}

  
</style>

<style>
/* 移动端 */
@media screen and (max-width: 1023px) {
  .el-image-viewer__btn {
    border-radius: 0;
    opacity: 1;
  }
  .el-image-viewer__close {
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    font-size: 4px;
  }
  
  .el-image-viewer__next, .el-image-viewer__prev {
    width: 10px;
    height: 10px;
    font-size: 4px;
  }
  .el-image-viewer__prev {
    left: 10px;
    text-indent: 1px;
  }
  .el-image-viewer__next {
    right: 10px;
    text-indent: 1px;
  }
  .el-image-viewer__actions {
    bottom: 8px;
    width: 60px;
    height: 10px;
    padding: 0 4px;
    border-radius: 5px;
  }
  .el-image-viewer__actions__inner {
    font-size: 6px;
  }
}

@media screen and (min-width: 1024px) {
  .el-image-viewer__btn {
    border-radius: 0;
    opacity: 1;
  }
  .el-image-viewer__close {
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    font-size: 4px;
  }
  
  .el-image-viewer__next, .el-image-viewer__prev {
    width: 10px;
    height: 10px;
    font-size: 4px;
  }
  .el-image-viewer__prev {
    left: 10px;
    text-indent: 1px;
  }
  .el-image-viewer__next {
    right: 10px;
    text-indent: 1px;
  }
  .el-image-viewer__actions {
    bottom: 8px;
    width: 60px;
    height: 10px;
    padding: 0 4px;
    border-radius: 5px;
  }
  .el-image-viewer__actions__inner {
    font-size: 6px;
  }
}
  .el-image-viewer__btn {
    border-radius: 0;
    opacity: 1;
  }
  .el-image-viewer__close {
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    font-size: 4px;
  }
  
  .el-image-viewer__next, .el-image-viewer__prev {
    width: 10px;
    height: 10px;
    font-size: 4px;
  }
  .el-image-viewer__prev {
    left: 10px;
    text-indent: 1px;
  }
  .el-image-viewer__next {
    right: 10px;
    text-indent: 1px;
  }
  .el-image-viewer__actions {
    bottom: 8px;
    width: 60px;
    height: 10px;
    padding: 0 4px;
    border-radius: 5px;
  }
  .el-image-viewer__actions__inner {
    font-size: 6px;
  }
</style>